@import '../../../../scss/theme-bootstrap';

.egift__redemption-banner {
  clear: both;
  padding: 1em;
  text-align: center;
  background-color: $color-pink;
  color: $color-white;
  text-transform: uppercase;
  position: fixed;
  width: 100%;
  display: none;
  p {
    margin-bottom: 0;
  }
}
